import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import { ToastContainer, toast } from "react-toastify";
import { object, string } from "yup";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import axios from "axios";
import GamePdf from "../../assets/PDF/BambuGames_Katalog.pdf";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { useParams } from "react-router-dom";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import table from "../../assets/images/table.svg";
import activeTable from "../../assets/images/active-table.svg";
import passiveTable from "../../assets/images/passive-table.svg";

const Game = () => {
  const { id } = useParams(); // id parametresini al
  console.log("id", id);
  const { t, i18n } = useTranslation();
  const [games, setGames] = useState();


  axios.defaults.baseURL = "https://api.bambugames.com.tr/api";

  const getGames = async () => {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `/games/details/${id}`,
    };
    const response = await axios(config)
      .then((response) => {
        console.log("response", response.data.data);
        setGames(response.data.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        return error.response;
      });
  };

  useEffect(() => {
    getGames();
  }, []);



  // useEffect(()=>{
  //     /* eslint-disable */
  // },[])

  const onSubmit = (e) => {
    e.preventDefault();
    let json = {};
    const data = new FormData(e.target);

    data.forEach((value, key) => {
      json[key] = value;
    });
    if (i18n.language == "tr") {
      const schema = object().shape({
        name: string("Sadece metin yazabilirsiniz").required("İsim giriniz!"),
        mail: string("Sadece metin yazabilirsiniz")
          .email("Email formatı yanlış!")
          .required("Email giriniz!"),
        profile: string("Sadece metin yazabilirsiniz").required(
          "Müşteri pofili giriniz!"
        ),
        subject: string("Sadece metin yazabilirsiniz").required(
          "Konu giriniz!"
        ),
        message: string("Sadece metin yazabilirsiniz").required(
          "Mesaj giriniz!"
        ),
      });
      schema
        .validate(json)
        .then((r) => {
          axios
            .post("/api/send-contact-form", json)
            .then((res) => {
              toast.success(res.data.message);
            })
            .catch((err) => {
              if (err.response.status == 400) {
                toast.error(err.response.data);
              } else {
                toast.error(err.message);
              }
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      const schema = object().shape({
        name: string("You can only write text").required("Enter name!"),
        mail: string("You can only write text")
          .email("Email format is wrong!")
          .required("Enter your email!"),
        profile: string("You can only write text").required(
          "Enter your customer profile!"
        ),
        subject: string("You can only write text").required("Enter subject!"),
        message: string("You can only write text").required("Enter message!"),
      });
      schema
        .validate(json)
        .then((r) => {
          axios
            .post("/api/send-contact-form", json)
            .then((res) => {
              toast.success(res.data.message);
            })
            .catch((err) => {
              if (err.response.status == 400) {
                toast.error(err.response.data);
              } else {
                toast.error(err.message);
              }
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };
  return (
    <>
      <div className="container-fluid position-relative first-background">
        <Header />

        <img
          className="left-img"
          src={require("../../assets/images/aboutus-left.png")}
        />
        <div className="row mt-5 position-relative">
          <div className="col-12 second-content">
          <img  className="game-banner"  src={`https://api.bambugames.com.tr/uploads/game-icons/${games?.icon}.png`} />
            <h1>{games?.name}</h1>
           
         
            <video
              loop
              muted
              autoPlay
              src={`https://api.bambugames.com.tr/uploads/game-gifs/${games?.icon}.mp4`}
              className="game-video"
            ></video>
            <p  className="position-relative game-desc-area" dangerouslySetInnerHTML={{__html: games?.desc[0][i18n.language]}}>
         
            </p>
          </div>
        </div>
      </div>

      {/* CONTACT FORM AREA */}
      <div className="container-fluid second-bacground position-relative">
        <img
          className="bambu-right-bottom"
          src={require("../../assets/images/bambu-right-bottom.png")}
        />
        <div className="row d-flex">
          <div
            className="col-12 d-flex align-items-center justify-content-center"
            style={{ marginTop: 200 }}
          >
            <div className="contact-area position-relative" id="iletisim">
              <h2>{t("deskQuest")}</h2>
              <p style={{ width: "70%" }}>{t("deskQuestDesc")}</p>

              <form onSubmit={(e) => onSubmit(e)}>
                <div className="row w-100" style={{ gap: "20px 0px" }}>
                  <div className="col-lg-6">
                    <input
                      name="name"
                      placeholder={t("formName")}
                      className="contact-input w-100"
                    ></input>
                  </div>
                  <div className="col-lg-6">
                    <input
                      name="mail"
                      placeholder={t("formMail")}
                      className="contact-input w-100"
                    ></input>
                  </div>
                  <div className="col-lg-6">
                    <select name="profile" className="select-input w-100">
                      <option value="bireysel">{t("person")}</option>
                      <option value="kurumsal">{t("business")}</option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <input
                      name="subject"
                      placeholder={t("formSubject")}
                      className="contact-input w-100"
                    ></input>
                  </div>
                  <div className="col-12">
                    <textarea
                      name="message"
                      placeholder={t("formMessage")}
                      className="contact-input-textarea w-100"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <button
                  type="submit"
                  className="pink-button"
                  style={{ marginTop: 70, position: "relative", zIndex: "4" }}
                >
                  {" "}
                  {t("formButton")}{" "}
                  <img src={require("../../assets/images/arrow.png")} />{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Footer />
      </div>
    </>
  );
};
export default Game;
