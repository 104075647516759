import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker , InfoWindow} from '@react-google-maps/api';
import axios from "axios";
import table from '../../assets/images/table.svg';
import activeTable from '../../assets/images/active-table.svg';
import passiveTable from '../../assets/images/passive-table.svg';
const Table = () => {
    const [cafes,setCafes] = useState();
    const libraries = ['places'];
    const mapContainerStyle = {
        width: '100%',
        height: '100%',
    };
    const center = {
        lat: 40.211237, // default latitude
        lng: 28.992714, // default longitude
    };
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDQmAo3BBhOb6ow7xmNdgDZV5TF8uWKGJQ',
        libraries,
    });
    axios.defaults.baseURL = 'https://api.bambugames.com.tr/api';
    const getCafes = async () => {
        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTBkODFmMjYxZjBiNzE4MWFiNjgyMTUiLCJpYXQiOjE3MDE3MDMxMTcsImV4cCI6MTcwMjMwNzkxN30.C2cEnVUe1Jj5Ul1zrYOl9nk0pavXAI1PVgkcoZw-Yt4",
            },
            url: `/cafes/`
        };
        const response = await axios(config)
            .then((response) => {
                console.log("response",response.data);
                setCafes(response.data.data);
            })
            .catch((error) => {
                return error.response;
            }
        );
    }
    const [activeMarker, setActiveMarker] = useState(null);
    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
            setActiveMarker(marker);
        };
    

    useEffect(()=>{
       getCafes(); 
    },[])
    if (loadError) {
        getCafes(); 
        return <div>Error loading maps</div>;
    }
    
    if (!isLoaded) {
    return <div>Loading maps</div>;
    }
    return(
        <div>
            
        </div>
    )
}
export default Table;