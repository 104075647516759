import Header from "../Layouts/header";
import Footer from "../Layouts/footer";
import { ToastContainer, toast } from "react-toastify";
import { object, string } from "yup";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import axios from "axios";
import GamePdf from "../../assets/PDF/BambuGames_Katalog.pdf";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import table from "../../assets/images/table.svg";
import activeTable from "../../assets/images/active-table.svg";
import passiveTable from "../../assets/images/passive-table.svg";

const Home = () => {
  const { t, i18n } = useTranslation();
  const [games, setGames] = useState();
  const [leftSwiper, setLeftSwiper] = useState();
  const [rightSwiper, setRightSwiper] = useState();
  const [whyDatas, setWhyDatas] = useState();
  
  const whyData = [
    "Tek bir dijital masa ile bilindik tüm kutu oyunlarına sahip olursunuz.",
    "Sürekli güncellenen ve yeni eklenen oyunlar ile müşterilerinize yeni deneyimler sunmaya devam edersiniz.",
    "Dijital masalarımız sayesinde müşterileriniz işletmenizde daha fazla zaman geçirir.",
    "Para ile çalışan masalarımızdan satın alarak ekstra gelir elde edebilirsiniz.",
    "Yenilikçi teknolojilere sahip olmak size yeni müşteriler kazandırır.",
    ];

    const whyDataEn = [
        "You have all the known board games with a single digital table.",
        "You continue to offer new experiences to your customers with constantly updated and newly added games.",
        "Your customers spend more time in your business thanks to our digital tables.",
        "You can earn extra income by purchasing our money-operated tables.",
        "Having innovative technologies will bring you new customers.",
    ];



  const getGames = async () => {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `/games/`,
    };
    const response = await axios(config)
      .then((response) => {
        setGames(response.data.data);
      })
      .catch((error) => {
        return error.response;
      });
  };

  const fetchData = async () => {
    try {
      const half = Math.ceil(games.length / 2);
      setLeftSwiper(games.slice(0, half));
      setRightSwiper(games.slice(half));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGames();
  }, []);

  useEffect(() => {
    i18n.language == "tr" ? setWhyDatas(whyData) : setWhyDatas(whyDataEn);
    }, [i18n.language]);
  useEffect(() => {
    // console.log("games",games);
    fetchData();
  }, [games]);

  axios.defaults.baseURL = "https://api.bambugames.com.tr/api";

  const [cafes, setCafes] = useState();
  // const libraries = ['places'];
  const mapContainerStyle = {
    width: "100%",
    height: "70vh",
    borderRadius: "10px",
    border: "2px solid #ffffff",
  };
  const center = {
    lat: 40.211237, // default latitude
    lng: 28.992714, // default longitude
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDQmAo3BBhOb6ow7xmNdgDZV5TF8uWKGJQ",
    // libraries,
  });
  const getCafes = async () => {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTBkODFmMjYxZjBiNzE4MWFiNjgyMTUiLCJpYXQiOjE3MDE3MDMxMTcsImV4cCI6MTcwMjMwNzkxN30.C2cEnVUe1Jj5Ul1zrYOl9nk0pavXAI1PVgkcoZw-Yt4",
      },
      url: `/cafes/`,
    };
    const response = await axios(config)
      .then((response) => {
        // console.log("response",response.data);
        setCafes(response.data.data);
      })
      .catch((error) => {
        return error.response;
      });
  };
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    getCafes();
  }, []);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  if (loadError) {
    getCafes();
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  // useEffect(()=>{
  //     /* eslint-disable */
  // },[])

  const onSubmit = (e) => {
    e.preventDefault();
    let json = {};
    const data = new FormData(e.target);

    data.forEach((value, key) => {
      json[key] = value;
    });
    if (i18n.language == "tr") {
      const schema = object().shape({
        name: string("Sadece metin yazabilirsiniz").required("İsim giriniz!"),
        mail: string("Sadece metin yazabilirsiniz")
          .email("Email formatı yanlış!")
          .required("Email giriniz!"),
        profile: string("Sadece metin yazabilirsiniz").required(
          "Müşteri pofili giriniz!"
        ),
        subject: string("Sadece metin yazabilirsiniz").required(
          "Konu giriniz!"
        ),
        message: string("Sadece metin yazabilirsiniz").required(
          "Mesaj giriniz!"
        ),
      });
      schema
        .validate(json)
        .then((r) => {
          axios
            .post("/api/send-contact-form", json)
            .then((res) => {
              toast.success(res.data.message);
            })
            .catch((err) => {
              if (err.response.status == 400) {
                toast.error(err.response.data);
              } else {
                toast.error(err.message);
              }
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      const schema = object().shape({
        name: string("You can only write text").required("Enter name!"),
        mail: string("You can only write text")
          .email("Email format is wrong!")
          .required("Enter your email!"),
        profile: string("You can only write text").required(
          "Enter your customer profile!"
        ),
        subject: string("You can only write text").required("Enter subject!"),
        message: string("You can only write text").required("Enter message!"),
      });
      schema
        .validate(json)
        .then((r) => {
          axios
            .post("/api/send-contact-form", json)
            .then((res) => {
              toast.success(res.data.message);
            })
            .catch((err) => {
              if (err.response.status == 400) {
                toast.error(err.response.data);
              } else {
                toast.error(err.message);
              }
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };
  return (
    <>
      <div className="container-fluid position-relative first-background">
        <Header />
        <div className="first-content  mt-5">
          <div className="row">
            <div className="col-md-6 col-12 d-flex">
              <div className="row">
                <div className="col-4"></div>
                <div
                  className="col-8 webView flex-column justify-content-center"
                  style={{ gap: "20px 0px" }}
                >
                  <span>
                    {t("firstSpan")}{" "}
                    <img src={require("../../assets/images/rocket.png")} />{" "}
                  </span>

                  <h1>{t("title1")}</h1>
                  <h1>
                    <a>
                      {" "}
                      <b>
                        <i>{t("title2")}</i>
                      </b>{" "}
                    </a>
                  </h1>
                  <p>{t("firstAreaDescription")}</p>
                  {/* <p>Alıştığınız masa oyunlarını, vizyoner yaklaşımımız ile harmanlayarak sizlere <br/> yepyeni bir tecrübe sunuyoruz.</p>
                                    <p>İster tek başınıza, ister çift olarak, ister dört kişilik arkadaş gruplarınızla bu yeni <br/> tecrübenin tadını çıkarın.</p> */}
                  <div className="button-area position-relative">
                    {/* <a href="#qrcode" className="pink-button position-relative"> {t('firstAreaPingButton')} <img src={require('../../assets/images/arrow.png')}/> </a> */}
                    <a
                      href="#iletisim"
                      className="pink-button position-relative"
                    >
                      {t("firstAreaPurpleButton")}{" "}
                      <img src={require("../../assets/images/arrow.png")} />{" "}
                    </a>
                  </div>
                </div>
                <div
                  className="col-8 tabletView flex-column"
                  style={{ gap: "10px 0px" }}
                >
                  <span>
                    {t("firstSpan")}{" "}
                    <img src={require("../../assets/images/rocket.png")} />{" "}
                  </span>

                  <h1>{t("title1")}</h1>
                  <h1>
                    <a>
                      {" "}
                      <b>
                        <i>{t("title2")}</i>
                      </b>{" "}
                    </a>
                  </h1>
                  <p>{t("firstAreaDescription")}</p>
                  {/* <p>Alıştığınız masa oyunlarını, vizyoner yaklaşımımız ile harmanlayarak sizlere yepyeni bir tecrübe sunuyoruz.</p>
                                    <p>İster tek başınıza, ister çift olarak, ister dört kişilik arkadaş gruplarınızla bu yeni tecrübenin tadını çıkarın.</p> */}
                  <div className="button-area">
                    {/* <a href="#qrcode" className="pink-button position-relative"> {t('firstAreaPingButton')} <img src={require('../../assets/images/arrow.png')}/> </a> */}
                    <a
                      href="#iletisim"
                      className="pink-button position-relative"
                    >
                      İletişime Geçin{" "}
                      <img src={require("../../assets/images/arrow.png")} />{" "}
                    </a>
                  </div>
                </div>
                <div
                  className="col-8 mobileView flex-column align-items-center"
                  style={{ gap: "20px 0px" }}
                >
                  <span>
                    {t("firstSpan")}{" "}
                    <img src={require("../../assets/images/rocket.png")} />{" "}
                  </span>

                  <h1>{t("title1")}</h1>
                  <h1>
                    <a>
                      {" "}
                      <b>
                        <i>{t("title2")}</i>
                      </b>{" "}
                    </a>
                  </h1>
                  <p>{t("firstAreaDescription")}</p>
                  {/* <p>Alıştığınız masa oyunlarını, vizyoner yaklaşımımız ile harmanlayarak sizlere yepyeni bir tecrübe sunuyoruz.</p>
                                    <p>İster tek başınıza, ister çift olarak, ister dört kişilik arkadaş gruplarınızla bu yeni tecrübenin tadını çıkarın.</p> */}
                  <div className="button-area">
                    {/* <a href="#qrcode" className="pink-button"> {t('firstAreaPingButton')} <img src={require('../../assets/images/arrow.png')}/> </a> */}
                    <a href="#iletisim" className="pink-button">
                      {t("firstAreaPurpleButton")}
                      <img
                        src={require("../../assets/images/arrow.png")}
                      />{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-12 mobile-margin d-flex align-items-center justify-content-center"
              style={{ position: "relative" }}
            >
              <img
                style={{ maxWidth: "100%" }}
                src={require("../../assets/images/table/table.png")}
              />
              <img
                className="right-bambu"
                src={require("../../assets/images/right-bambu.png")}
              />
              <img
                className="rock webView"
                src={require("../../assets/images/rock.png")}
                id="hakkimizda"
              />
            </div>
          </div>
        </div>
        <img
          className="left-img"
          src={require("../../assets/images/aboutus-left.png")}
        />
        <div className="row mt-5 position-relative">
          <div className="col-12 second-content">
            <span>
              <img src={require("../../assets/images/ship.png")} />
              BAMBU GAMES YAZILIM SANAYİ VE TİCARET A.Ş.
            </span>
            <h1>{t("menuAbout")}</h1>
            {/* <img className="position-relative interface" src={require('../../assets/images/table/arayuz.png')}/> */}
            <p style={{ width: "80%" }} className="position-relative">
              {t("aboutUsDescription")}
            </p>
          </div>
          <div
            id="our-games"
            className="col-12"
            style={{ marginTop: 200, marginBottom: 100 }}
          >
            <h1 className="text-center games-title">{t("games")}</h1>
          </div>
          <div className="col-12">
            {leftSwiper && leftSwiper.length > 0 && (
              <Swiper
                // slidesPerView={6}
                breakpoints={{
                  0: {
                    slidesPerView: 1.5,
                  },
                  520: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                  1400: {
                    slidesPerView: 5,
                  },
                  1700: {
                    slidesPerView: 6,
                  },
                }}
                spaceBetween={15}
                centeredSlides={true}
                autoplay={true}
                loop={true}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {leftSwiper?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <a href={`/game/${item._id}`} >
                    <img
                      className="swiper-img"
                      src={`https://api.bambugames.com.tr/uploads/game-icons/${item.icon}.png`}
                    />
                    </a>
                   
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            {rightSwiper && rightSwiper.length > 0 && (
              <Swiper
                // slidesPerView={6}
                breakpoints={{
                  0: {
                    slidesPerView: 1.5,
                  },
                  520: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                  1400: {
                    slidesPerView: 5,
                  },
                  1700: {
                    slidesPerView: 6,
                  },
                }}
                spaceBetween={15}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  disableOnInteraction: true,
                  //waitForTransition: false,
                  reverseDirection: true,
                }}
                modules={[Autoplay]}
                initialSlide={rightSwiper?.length}
                className="mySwiper2"
              >
                {rightSwiper?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <a href={`/game/${item._id}`} >
                    <img
                      className="swiper-img"
                      src={`https://api.bambugames.com.tr/uploads/game-icons/${item?.icon}.png`}
                    />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
      <div className="container position-relative ">
        {/* GHOST SCREEN */}
        <div id="ghost-screen" style={{ marginTop: 200 }}>
          <div className="row">
            <div className="col-md-5 col-12 gost">
              <div className="row">
                <div className="gost-section position-relative">
                  <h3 className="d-flex flex-row justify-content-between gost-mobile">
                    {t("patent")}
                  </h3>
                  <div className="d-flex flex-row justify-content-between gost-mobile">
                    <h2>{t("ghost")}</h2>
                    {/* <span>Dünya'da bir ilk!</span> */}
                  </div>
                  <p>{t("ghostScreenDesc")}</p>
                </div>
              </div>
            </div>
            <div
              className="col-md-7 col-12 mobile-margin d-flex align-items-center justify-content-center"
              style={{ position: "relative" }}
            >
              <img
                style={{ maxWidth: "100%" }}
                src={require("../../assets/images/gost-screen.png")}
              />
            </div>
          </div>
        </div>

        {/* INTERFACE */}
        <div id="interface" style={{ marginTop: 200 }}>
          <div className="row">
            <div
              className="col-md-7 col-12 mobile-margin d-flex align-items-center justify-content-center"
              style={{ position: "relative" }}
            >
              <img
                className="interface"
                src={require("../../assets/images/table/arayuz.png")}
              />
            </div>
            <div className="col-md-5 col-12 gost ">
              <div className="gost-section position-relative">
                <h3 className="d-flex flex-row justify-content-end gost-mobile">
                  {t("interFaceTitle")}
                </h3>
                <div className="d-flex flex-row justify-content-end gost-mobile">
                  <h2>{t("interFaceTitle2")}</h2>
                  {/* <span>Dünya'da bir ilk!</span> */}
                </div>
                <p className="text-end">{t("interFaceDesc")}</p>
              </div>
            </div>
          </div>
        </div>

         {/* NEDEN SAHİP OLMALISINIZ? */}
         <div id="why-area" style={{ marginTop: 200 }}>
          <div className="row">
            <div className="col-md-5 col-12 gost">
              <div className="row">
                <div className="gost-section position-relative">
                  <h3 className="d-flex flex-row justify-content-between gost-mobile">
                  {t("whyTitle")}
                  </h3>
                  <div className="d-flex flex-row justify-content-between gost-mobile">
              
                  <h2>    {t("whyTitle2")}</h2>
                    {/* <span>Dünya'da bir ilk!</span> */}
                  </div>
                  <ul className="why-area" >
                    {whyDatas.map((item,index)=>(
                      <li key={index}>{item}</li>
                    ))}
                </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-7 col-12 mobile-margin d-flex align-items-center justify-content-center"
              style={{ position: "relative" }}
            >
              <img
                style={{ maxWidth: "100%" }}
                src={require("../../assets/images/table/table.png")}
              />
            </div>
          </div>
        </div>

       
     

        {/* CATALOG */}
        <div
          className="col-12 d-flex align-items-center justify-content-center position-relative"
          style={{ marginTop: 200 }}
        >
          <div className="contact-area" id="catalog">
            <h2>{t("catalog")}</h2>
            <p>{t("catalogDescription")}</p>
            <a
              href="https://www.canva.com/design/DAGDFRtW6Wc/3ZYi4KHGTvAQrILVVimNDA/watch?utm_content=DAGDFRtW6Wc&utm_campaign=designshare&utm_medium=link&utm_source=editor"
              target="_blank"
              className="pink-button"
            >
              {" "}
              {t("catalogButton")}{" "}
              <img src={require("../../assets/images/arrow.png")} />{" "}
            </a>
          </div>
        </div>
      </div>

      {/* CONTACT FORM AREA */}
      <div className="container-fluid second-bacground position-relative">
        <img
          className="bambu-right-bottom"
          src={require("../../assets/images/bambu-right-bottom.png")}
        />
        <div className="row d-flex">
          <div
            className="col-12 d-flex align-items-center justify-content-center"
            style={{ marginTop: 200 }}
          >
            <div className="contact-area position-relative" id="iletisim">
              <h2>{t("deskQuest")}</h2>
              <p style={{ width: "70%" }}>{t("deskQuestDesc")}</p>

              <form onSubmit={(e) => onSubmit(e)}>
                <div className="row w-100" style={{ gap: "20px 0px" }}>
                  <div className="col-lg-6">
                    <input
                      name="name"
                      placeholder={t("formName")}
                      className="contact-input w-100"
                    ></input>
                  </div>
                  <div className="col-lg-6">
                    <input
                      name="mail"
                      placeholder={t("formMail")}
                      className="contact-input w-100"
                    ></input>
                  </div>
                  <div className="col-lg-6">
                    <select name="profile" className="select-input w-100">
                      <option value="bireysel">{t("person")}</option>
                      <option value="kurumsal">{t("business")}</option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <input
                      name="subject"
                      placeholder={t("formSubject")}
                      className="contact-input w-100"
                    ></input>
                  </div>
                  <div className="col-12">
                    <textarea
                      name="message"
                      placeholder={t("formMessage")}
                      className="contact-input-textarea w-100"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <button
                  type="submit"
                  className="pink-button"
                  style={{ marginTop: 70, position: "relative", zIndex: "4" }}
                >
                  {" "}
                  {t("formButton")}{" "}
                  <img src={require("../../assets/images/arrow.png")} />{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Footer />
      </div>
    </>
  );
};
export default Home;
