import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsTR from './pages/lang/tr.json';
import translationsEN from './pages/lang/en.json';
import translationsDE from './pages/lang/de.json';

i18n
  .use(Backend)
  .use(LanguageDetector) // Tarayıcı dil algılayıcı
  .use(initReactI18next) // React ile entegrasyon
  .init({
    resources: {
      tr: {
        translation: translationsTR,
      },
      en: {
        translation: translationsEN,
      },
      de: {
        translation: translationsDE,
      },
    },
    fallbackLng: 'tr', // Tarayıcı dili bulunamazsa varsayılan dil
    supportedLngs: ['tr', 'en', 'de'], // Desteklenen diller
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'], // Dil algılama sırası
      caches: ['localStorage', 'cookie'], // Dil tercihini saklama
    },
    debug: true, // Geliştirme sırasında hata ayıklama için
    interpolation: {
      escapeValue: false, // React için gerekli değil
    },
  });

export default i18n;